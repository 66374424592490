/*
 *   File : pan-details.js
 *   Author URI : https://evoqins.com
 *   Description :  Component will allow the customer to submit PAN details.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react"
import { parse, format } from 'date-fns';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { CustomTextInput, DatePicker } from "../../../Component/Form-elements"
import { GradientButton } from "../../../Component/CTA";
import APIService from "../../../Service/api-service";
import { _getKycProfile } from "../../../Helper/api";

const PANDetailsPage = (props) => {

    const PAN_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA.pan_details);
    const [panNumber, setPanNumber] = useState(PAN_DATA.pan);
    const [panNumberError, setPanNumberError] = useState("");
    const [dob, setDOB] = useState(null);
    const [dobError, setDobError] = useState("");
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const _handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    // max date that can be selected for dob (current date - 18 years)
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    useEffect(() => {
        if (PAN_DATA.date_of_birth !== null) {
            const parsed_date = parse(PAN_DATA.date_of_birth, 'dd-MM-yyyy', new Date());
            const formatted_date = format(parsed_date, 'dd/MM/yyyy');
            setDOB(formatted_date);
        }
    }, [])

    // handler for input fields
    const _handleInputFields = (type, value) => {
        switch (type) {
            case 1:
                setPanNumber(value);
                setPanNumberError("");
                break;
            case 2:
                console.log("value", value)
                setDOB(value);
                setDobError("");
                break;
            case 3: 
                setName(value);
                setNameError("");
                break;
        }
    }

    const _validate = () => {
        let valid = true;

        if (dob === null) {
            setDobError("Enter date of birth");
            valid = false;
        } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
            setPanNumberError("Enter valid pan number");
            valid = false;
        } 
        else if (name === "") {
            setNameError("Please enter valid name");
            valid = false;
        }
        else if (valid === true) {
            setLoader(true);
            _saveDetails();
        }
    };


    // API - save pan number and dob for kyc
    const _saveDetails = async () => {
        const formatted_date = format(parse(dob, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
        let url = 'kyc/check';
        let payload = JSON.stringify({
            pan: panNumber,
            dob: formatted_date,
            name_on_pan: name
        });

        try {
            const response = await APIService(true, url, payload);

            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("PAN number and DOB saved successfully", {
                    type: "success",
                });
                const kyc_data = await _getKycProfile();
                props.updateProgress(3);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error.message, {
                type: "error",
            });
        }
        finally {
            setLoader(false);
        }
    };


    return (
        <div>
            <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-2">Enter your PAN details</p>
            <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">Submit your PAN card details. This is a regulatory requirement.</p>
            <div className="row margin-34px-top mb-5">
                <div className="col-md-5 padding-62px-right-mob padding-5px-right-mob">
                    <CustomTextInput label="PAN number"
                        postfix="*"
                        pan={true}
                        value={panNumber}
                        maxLength={10}
                        inputClass="text-uppercase"
                        error={panNumberError}
                        className="margin-42px-bottom "
                        handleChange={(value) => _handleInputFields(1, value)} />
                    
                    <CustomTextInput label="Enter your name on PAN"
                        postfix="*"
                        value={name}
                        error={nameError}
                        className="margin-42px-bottom "
                        handleChange={(value) => _handleInputFields(3, value)} />

                    <DatePicker label="Enter date of birth as per PAN"
                        error={dobError}
                        value={dob}
                        isAdult={true}
                        maxDate={maxDate}
                        postFix="*"
                        dob={(value) => _handleInputFields(2, value)} />

                    <GradientButton label="Continue"
                        loading={loader}
                        className="px-3 padding-12px-tb e-font-16 margin-40px-top mob-w-100"
                        handleClick={_validate} />
                </div>
                <div className="col-md-5 padding-5px-right-mob mt-lg-0 mt-md-0 mt-4 d-flex justify-content-end align-items-start">
                    <img src={require("../../../Assets/Images/kyc/pan-image.png")}
                        alt="Pan"
                        width={ screenWidth > 576 ? 414 : 380}
                        draggable={false} />
                </div>
            </div>
        </div>
    )
}

export default PANDetailsPage