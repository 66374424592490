/*
 *   File : sip-detail.js
 *   Author URI : https://evoqins.com
 *   Description :  SIP transaction details of a selected transition
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react"
import { Modal } from "bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify";

import { GradientButton } from "../CTA";
import Icon from "../Icon/icon";
import { updateNavigation } from "../../Store/Action/action";
import { CancelSIPModal, PauseSIPModal, InvestGoalModal, PaymentStatusModal } from "../Modal";
import { InvestmentHeader } from "../Header";
import { BreadCrumb } from "../BreadCrumb";
import { _getMFInvestmentSummary, _getMFDetails } from "../../Helper/api";
import { CustomLoader } from "../Other";

import { _getDayOfMonth } from "../../Helper";
import APIService from "../../Service/api-service";

import style from "../../Styles/Component/sip-detail.module.scss";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage SIPs', url: '/sip' },
    { page: 'SIP detail', url: null }
];



const SIPDetail = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const sipId = location.state && location.state.sip_id;
    const [cancelSipModal, setCancelSipModal] = useState(false);
    const [pauseSipModal, setPauseSipModal] = useState(false);
    const [showInvestmentModal, setShowInvestModal] = useState(false);
    const [sipDetailData, setSipDetailData] = useState({});
    const [loader, setLoader] = useState(true);
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [fundDetail, setFundDetail] = useState({});
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);
    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (sipId) {
            _getSIPDetail();
        }
        else {
            navigate(-1);
        }
    }, [sipId])
    // listener for opening cancel modal
    useEffect(() => {
        if (cancelSipModal === true) {
            _openCancelModal();
        }
    }, [cancelSipModal]);

    // listener for opening pause modal
    useEffect(() => {
        if (pauseSipModal === true) {
            _openPauseModal();
        }
    }, [pauseSipModal]);

    // listener for opening invest modal
    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestmentModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    const _handleNavigate = (order_id) => {
        navigate("/orders/fund-order-summary", { state: { order_id: order_id } });
        dispatch(updateNavigation("/orders"));
    }

    //to open cancel modal
    const _openCancelModal = () => {
        const modal = new Modal(document.getElementById("cancel-sip"), {});
        modal.show();
    };

    // Investment modal
    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }

    //to open cancel modal
    const _openPauseModal = () => {
        const modal = new Modal(document.getElementById("pause-sip"), {});
        modal.show();
    };

    const _handleCancelSip = () => {
        setCancelSipModal(true);
    }

    const _openPauseSipModal = () => {
        setPauseSipModal(true);
    }

    async function _getDetailData() {
        setButtonLoader(true);
        let params = {
            'id': sipDetailData.fund_id
        };

        _getMFDetails(params)
            .then((response) => {
                setFundDetail(response);
                _getInvestmentData();
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
                setButtonLoader(false);
            });
    }

    // API fund detail 
    const _getInvestmentData = () => {
        const { date } = _getDayOfMonth();
        _getMFInvestmentSummary(sipDetailData.fund_id, true, date)
            .then((response) => {
                setMfInvestmentData(response);
                setMountInvestModal(true);
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            });
        setButtonLoader(false);
    }

    // API - cancel sip
    async function _handleCancel(reason, remark) {
        let status = false;
        let url = 'sip/cancel';
        let payload = JSON.stringify({
            sip_id: sipId,
            reason,
            remark
        });
        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                status = true;
                toast.success(`SIP cancelled`, {
                    type: "success",
                });
                navigate("/sip", { state: { selectedTab: 3 } });

            }
            else {
                toast.error(`Failed to cancel SIP`, {
                    type: "error",
                });
            }
        });
        return status;
    }

    // API - get sip detail
    function _getSIPDetail() {
        let url = "sip/detail"
        let payload = JSON.stringify({
            sip_id: sipId
        });
        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                setSipDetailData(response.data);
                setLoader(false);
            }
            else {
                navigate(-1);
            }
        });
    }

    // API - pause SIP
    function _handlePauseSip() {
        let url = "sip/pause"
        let payload = JSON.stringify({
            sip_id: sipId
        });
        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                toast.success(`SIP paused`, {
                    type: "success",
                });
                setPauseSipModal(false);
                navigate("/sip", { state: { selectedTab: 2 } });
            }
            else {
                toast.error(`Failed to pause SIP`, {
                    type: "error",
                });
            }
        });
    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id) {
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setPaymentStatus(response.data.payment_status);
                setPaymentStatusText(response.data.payment_status_txt);
            }
        })
    }

    const Heading = (props) => {
        return (
            <div className={`d-flex ${props.className}`}>
                <p className="color-gray e-poppins-medium e-font-16-sm-12 line-height-16px mb-0 mt-sm-0 mt-1">{props.heading}&nbsp;</p>
                <p className="color-outer-space e-poppins-medium e-font-16-sm-12 line-height-16px-sm-24px mb-0">{props.answer}</p>
            </div>

        )
    }

    const PaymentAndOtherActivities = (props) => {
        return (
            <div className="mt-sm-3 mt-0 d-sm-block d-flex">
                <p className="color-gray e-poppins-medium e-font-16-sm-12 line-height-16px margin-10px-bottom e-payment-other-activities mb-0">{props.heading}
                <span className="d-lg-none d-inline">:</span>
                </p>
                <p className="color-black e-poppins-semi-bold e-font-18-sm-12 line-height-16px mb-0 ms-sm-0 ms-2 mt-sm-3 mt-0">{props.answer}</p>
            </div>
        )
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="e-page-container e-manage-sip margin-80px-bottom">
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />
                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">My SIP</p>
                <div className="row">
                    <div className="col-lg-10 col-12">
                        <div className="border-radius-24px border-all border-bright-gray p-sm-4 p-3">
                            <div className="border-bottom-1px border-bright-gray pb-3 d-flex justify-content-between align-items-center flex-wrap">
                                <div className="d-flex align-items-center gap-12px">
                                    <div className="border-all border-bright-gray border-radius-8px p-2">
                                        <img src={sipDetailData.image}
                                            alt="Quant Small Cap Fund Direct Plan Growth"
                                            width={36}
                                            height={36}
                                            className="object-fit-contain" />
                                    </div>
                                    <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-28px mb-0">{sipDetailData.name}</p>
                                </div>
                                <div className="d-sm-block d-none">
                                    <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3"><span className="e-inter-semi-bold">₹</span>{(sipDetailData.amount).toLocaleString("en-IN")}
                                    </p>
                                    <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0 mt-sm-0 mt-2">SIP amount</p>
                                </div>
                                <div className="d-sm-none d-flex">
                                    <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0 mt-sm-0 mt-2">SIP amount: </p>
                                    <p className="color-outer-space e-poppins-semi-bold e-font-20-sm-18 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-1 ms-sm-0 ms-2"><span className="e-inter-semi-bold">₹</span>{(sipDetailData.amount).toLocaleString("en-IN")}
                                    </p>

                                </div>
                            </div>
                            <div className="mt-sm-3 mt-1 d-flex  flex-wrap justify-content-between align-items-center">
                                <Heading heading="Last installment:"
                                    answer={sipDetailData.last_installment} />
                                <div className="d-flex align-items-center border-all border-primary-color border-radius-30px bg-pale-steel-blue px-sm-3 px-2 py-2 my-lg-0 my-md-0 my-sm-0 my-2  w-max-content">
                                    <p className="color-gray e-poppins-medium e-font-16-sm-12 line-height-16px mb-0">Next installment &nbsp;</p>
                                    <p className="color-primary-color e-poppins-semi-bold e-font-16-sm-12 mb-0 ">{sipDetailData.next_sip_date}</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-sm-2 mt-0 flex-wrap gap-16px">
                                <Heading heading="SIP created on:"
                                    answer={sipDetailData.sip_created_on}
                                />
                                <Heading heading="First purchase:"
                                    answer={sipDetailData.is_first_purchase === true ? "Yes" : "No"}
                                />
                            </div>
                            <div className={`${screenWidth > 576 ? 'border-radius-16px bg-light-grayish-white p-3 my-4 ' : 'mt-2'}  `}>
                                <div className="d-flex justify-content-between flex-wrap gap-16px">
                                    <Heading heading="SIP mandate:"
                                        answer={sipDetailData.mandate_id} />
                                    <Heading heading="Start date:"
                                        answer={sipDetailData.start_date}
                                    />
                                </div>
                                <div className="d-flex justify-content-between mt-sm-3 mt-2 flex-wrap gap-16px">
                                    <Heading heading="Bank:"
                                        answer={`${sipDetailData.bank_name} ${sipDetailData.account_number}`} />
                                    <Heading heading="End date:"
                                        answer={sipDetailData.end_date}
                                    />
                                </div>

                            </div>
                            <div className="border-top-1px border-bright-gray pt-3 d-flex justify-content-between algin-items-center flex-wrap">
                                <div className="d-flex align-items-center gap-16px">
                                    {/* <div className="border-all border-radius-6px border-bright-gray p-3 d-flex align-items-center gap-8px cursor-pointer"
                                        onClick={_openPauseSipModal}>
                                        <Icon icon="pause"
                                            size={16} />
                                        <p className="color-black e-poppins-medium e-font-14 mb-0">Pause SIP</p>
                                    </div> */}
                                    <p className="color-primary-color e-poppins-regular e-font-16-sm-12 mb-0 cursor-pointer"
                                        onClick={_handleCancelSip}>Cancel SIP</p>
                                </div>
                                <GradientButton label="Invest more"
                                    loading={buttonLoader}
                                    className="e-font-16 px-3 padding-12px-tb mt-lg-0 mt-md-0 mt-sm-0 mt-2 e-sip-detail-invest-btn"
                                    handleClick={_getDetailData} />
                            </div>
                        </div>

                        <p className="color-jett-black e-poppins-medium e-font-16 mt-3 mb-sm-3  mb-2 ms-sm-0 ms-3">Payments and other activities</p>
                        <div className={`${screenWidth > 576 ? 'border-all border-bright-gray border-radius-16px px-4' : 'px-2'}  pb-3 pt-sm-0 pt-0 d-flex flex-wrap justify-content-between gap-24px-sm-12 ms-sm-0 ms-2`}>
                            <PaymentAndOtherActivities heading="Total no. of installments"
                                answer={sipDetailData.total_number_of_installments} />
                            <PaymentAndOtherActivities heading="Missed installments"
                                answer={sipDetailData.missed_of_installments} />
                            <PaymentAndOtherActivities heading="Rejected installments"
                                answer={sipDetailData.rejected_of_installments} />
                            <PaymentAndOtherActivities heading="Remaining installments"
                                answer={sipDetailData.remaining_installments} />
                        </div>


                        {
                            sipDetailData.installments.length !== 0 &&
                            <div className="border-radius-24px border-all border-bright-gray p-4 mt-sm-3 mt-0">
                                {
                                    sipDetailData.installments.map((item, key) =>
                                        <div key={key}
                                            className={`d-flex gap-8px  cursor-pointer ${sipDetailData.installments.length - 1 !== key && "border-bottom-1px border-bright-gray mb-3 pb-3"} ${(item.status === "Upcoming" && 'e-pointer-events-none')}`}
                                            onClick={() => _handleNavigate(item.order_id)}>
                                            <Icon icon={item.status === "Success" ? "check-green" : item.status === "Failed" ? "cancelled" : "upcoming"}
                                                size={24} />
                                            <div className="w-100">
                                                <div className="d-flex justify-content-between align-items-center flex-wrap gap-16px">
                                                    <div>
                                                        <div className="d-flex gap-16px align-items-center mb-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-sm-16-14 line-height-24px mb-0">{item.title}: {item.status === "Upcoming" ? "Not paid yet" : item.status === "Success" ? "Paid" : item.status === "Failed" ? "Failed" : item.status}</p>
                                                            {
                                                                item.status !== "Upcoming" &&
                                                                <p className={`position-relative e-font-14 e-poppins-regular mb-0  ${style.e_sip}`}>SIP</p>
                                                            }
                                                        </div>
                                                        <p className="color-outer-space e-poppins-regular e-font-14 line-height-16px mb-0">{item.transaction_date}</p>
                                                    </div>
                                                    {
                                                        item.status === "Success" ?
                                                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0">Success</p>
                                                            :
                                                            item.status === "Failed" ?
                                                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0">Failed</p>
                                                                :
                                                                <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0">{item.status}</p>

                                                    }
                                                </div>


                                                {
                                                    item.failure_reason &&
                                                    <div className="row mt-2">
                                                        <div className="col-lg-10 col-md-10">
                                                            <div className="border-radius-8px  bg-antique-white py-3 px-2 d-flex align-items-center">
                                                                {/* <Icon icon="info"
                                                                            style={{ color :'red'}}
                                                                            className="color-lust"
                                                                            size={24} /> */}

                                                                <p className="color-outer-space e-poppins-regular e-font-14 line-height-22px mb-0">{item.failure_reason}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        }
                    </div>
                </div>

                {/* cancel SIP */}
                {
                    cancelSipModal === true &&
                    <CancelSIPModal cancel={_handleCancel}
                        close={() => setCancelSipModal(false)} />
                }

                {/* pause SIP */}
                {
                    pauseSipModal === true &&
                    <PauseSIPModal pauseSip={_handlePauseSip}
                        close={() => setPauseSipModal(false)} />
                }


                {
                    mountInvestModal === true ?
                        <InvestGoalModal data={fundDetail}
                            mfInvestmentData={mfInvestmentData}
                            selectedTab={1}
                            close={() => {
                                setMfInvestmentData({})
                                setShowInvestModal({})
                                setMountInvestModal(false);
                            }}
                            getPaymentStatus={_getPaymentStatus} />
                        :
                        null
                }

                {
                    openPaymentStatusModal === true && (
                        <PaymentStatusModal status={paymentStatus}
                            paymentStatus={paymentStatusText}
                            close={() => setOpenPaymentStatusModal(false)} />
                    )
                }

            </div>
    )
}

export default SIPDetail