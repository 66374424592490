/*
 *   File :  declaration.js
 *   Author URI : https://evoqins.com
 *   Description : Self-declaration page which needs to be done by the investor for submitting the KYC info.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import Icon from "../../../Component/Icon/icon"
import { GradientButton } from "../../../Component/CTA";
import { DeclarationErrorModal } from "../../../Component/Modal";
import APIService from "../../../Service/api-service";
import { _getKycProfile } from "../../../Helper/api";

const DeclarationsPage = (props) => {

    const DECLARATION_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA.declaration);
    const [payTax, setPayTax] = useState(true);
    const [politicallyExposed, setPoliticallyExposed] = useState(true);
    const [accept, setAccept] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Update progress data from API
    useEffect(() => {
        if (DECLARATION_DATA !== undefined) {
            setPayTax(DECLARATION_DATA.tax_payer_in_india);
            setPoliticallyExposed(DECLARATION_DATA.politically_exposed);
            setAccept(DECLARATION_DATA.terms_and_condition);
        }
    }, [DECLARATION_DATA]);

    useEffect(()=>{
        if (showErrorModal === true) {
            const modal = new Modal(document.getElementById("declaration-error"), {});
            modal.show();
        }
    },[showErrorModal]);

    const _handleRadioFields = (type) => {
        switch (type) {
            case 1:
                setPayTax(!payTax)
                break;
            case 2:
                setPoliticallyExposed(!politicallyExposed);
                break;
            case 3:
                setAccept(!accept);
                break;
        }
    }

    function _callBackModalClose() {
        setShowErrorModal(false);
        setErrorMessage("");
    }
    const _validate = () => {
        let valid = true;
        if (payTax === false || politicallyExposed === false || accept === false) {
            valid = false;
            toast.dismiss();
            toast.error("All declaration must be selected to continue with KYC flow", {
                type: "error",
            });
        }
        if (valid === true) {
            setApiLoader(true);
            _submitDeclaration();
        }
    }

    // API - submit declaration
    async function _submitDeclaration() {
        let url = 'kyc/declaration';
        let payload = JSON.stringify({
            tax_payer_in_india: payTax,
            politically_exposed: politicallyExposed,
            terms_and_condition: accept
        });

        try {
            const response = await APIService(true, url, payload);

            if (response.status_code === 200) {
                const kyc_data = await _getKycProfile();
                props.updateProgress(6);
            } else if (response.status_code === 400) {
                setErrorMessage(response.message);
                setShowErrorModal(true);
            }
            
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error.message, {
                type: "error",
            });
        }
        finally {
            setApiLoader(false)
        }
    }


    return (
        <Fragment>
            <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-2">Declarations for your Master Capital Services Ltd</p>
            <div className="row pb-sm-0 pb-5">
                <div className="col-lg-6 col-md-6 col-12">
                    <p className="color-outer-space e-poppins-regular e-font-sm-16-4 line-height-24px mb-0">
                        By pressing continue, you acknowledge and confirm that the information provided by you is true and correct.
                    </p>
                    <div className="d-flex align-items-center gap-8px margin-40px-top-sm-16 cursor-pointer w-fit-content"
                        onClick={() => _handleRadioFields(1)}>
                        <Icon icon={payTax === true ? "radio-selected" : "radio-unselected"}
                            size={20} />
                        <p className="color-black e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">I pay my taxes in India</p>
                    </div>
                    <div className="d-flex align-items-center gap-8px mt-sm-3 mt-2 cursor-pointer w-fit-content"
                        onClick={() => _handleRadioFields(2)}>
                        <Icon icon={politicallyExposed === true ? "radio-selected" : "radio-unselected"}
                            size={20} />
                        <p className="color-black e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">I am not politically exposed or related</p>
                    </div>
                    <div className="d-flex align-items-center gap-8px mt-sm-3 mt-2 cursor-pointer w-fit-content"
                        onClick={() => _handleRadioFields(3)}>
                        <Icon icon={accept === true ? "radio-selected" : "radio-unselected"}
                            size={20} />
                        <p className="color-black e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">I accept <a href={"https://www.mastertrust.co.in/terms-and-condition"}
                            target="_blank"
                            rel="noreferrer"
                            className="color-primary-color text-decoration-underline">Master Capital Services Ltd terms & conditions</a></p>
                    </div>

                    <GradientButton label="Continue"
                        loading={apiLoader}
                        className="px-3 padding-12px-tb e-font-16 margin-40px-top-sm-16 mob-w-100"
                        handleClick={_validate} />
                </div>
                <div className="col-6 d-lg-block d-md-block d-none">
                    <img src={require("../../../Assets/Images/kyc/declaration.png")}
                        alt="Declaration"
                        draggable={false}
                        height={360}
                        className="w-100 object-fit-contain" />

                </div>
            </div>
            {
                showErrorModal === true && 
                <DeclarationErrorModal close={_callBackModalClose}
                    message={errorMessage}/>
            }
        </Fragment>
    )

}

export default DeclarationsPage