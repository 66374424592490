
/*
 *   File : breadcrumb.js
 *   Author URI : https://evoqins.com
 *   Description : UI component to page navigation
 *   Integrations : null
 *   Version : v1.1
 */


import { memo } from "react";
import { useNavigate } from 'react-router-dom';

import Icon from "../Icon/icon";

export default memo(BreadCrumb);
function BreadCrumb(props) {

    const navigator = useNavigate();

    // handle breadcrumb node click 
    function _handleRedirection (url) {
        // return when clicking current page
        navigator(url);
    };

    // props;
    let { data } = props;

    return (
        <p className={`mb-0 ${props.className} mt-sm-0 mt-3`}>
            {data.map((page, index) => (
                <span key={index} className={` e-font-12 ${index !== data.length - 1 ? 'e-poppins-regular color-gray cursor-pointer e-active-breadcrumb' : 'color-black e-poppins-medium'}`}
                    onClick={()=>{if (index < 2) _handleRedirection(page.url)}}>
                    {page.page}
                    {
                        index !== data.length - 1 && 
                            <Icon icon="gray-right-arrow" size={16} />
                    }
                </span>
            ))}
        </p>
    )
}