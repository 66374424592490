/*
 *   File :  nfo-listing.js
 *   Author URI : https://evoqins.com
 *   Description : Listing page for NFO.
 *   Integrations : null
 *   Version : v1.1
 */
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { InvestmentHeader } from "../../Component/Header";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { CustomLoader } from "../../Component/Other";
import { DataTable } from "../../Component/Form-elements";
import { SectionTitle } from "../../Component/Title";
import Footer from "../../Component/Footer/footer";
import { GradientButton } from "../../Component/CTA";
import { InvestGoalModal, PaymentStatusModal } from "../../Component/Modal";

import { _getMFInvestmentSummary } from "../../Helper/api";
import { _getDayOfMonth } from "../../Helper";

import Colors from '../../Styles/color.module.scss';

import APIService from "../../Service/api-service";

const CUSTOM_STYLES = {
    headRow: {
        style: {
            paddingRight: "0px",
            fontSize: "16px",
            paddingBottom: "8px !important",
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center'
            },
        },
    },
    rows: {
        style: {
            padding: "0px",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },

    cells: {
        style: {
            justifyContent: "center",
            '&:first-child': {
                padding: "24px 0 24px 24px !important"
            },
            '&:last-child': {
                padding: "24px 24px 24px 0 !important"
            },
        }
    }
};

const NEWS_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'NFO', url: null },
];

const _sortReturns = (rowA, rowB, key) => {
    const date_a = new Date(rowA[key]);
    const date_b = new Date(rowB[key]);

    if (date_a.getTime() > date_b.getTime()) {
        return 1;
    }

    if (date_b.getTime() > date_a.getTime()) {
        return -1;
    }

    return 0;
};

export default function NFOListing(props) {

    const navigator = useNavigate();
    const [apiLoader, setApiLoader] = useState(true);
    const [nfoData, setNfoData] = useState({});
    const [showInvestmentModal, setShowInvestModal] = useState({});
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const FUNDS_COLUMN = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '575px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-12px">
                        <span className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white">{row['name']}</span>
                        <span className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                            {row['type']}
                        </span>
                    </div>

                </div>
        },
        {
            id: 2,
            name: 'Launch date',
            selector: row => row['start_date'],
            sortable: true,
            left: true,
            sortFunction: (a, b) => _sortReturns(a, b, 'start_date'),
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['start_date']}
                </p>
        },
        {
            id: 3,
            name: 'Closing date',
            selector: row => row['end_date'],
            sortable: true,
            left: true,
            sortFunction: (a, b) => _sortReturns(a, b, 'end_date'),
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['end_date']}
                </p>
        },
        {
            id: 4,
            name: '',
            selector: '',
            sortable: false,
            left: true,
            cell: row =>
                <GradientButton label="Apply now"
                    className="py-2 px-3"
                    handleClick={() => setShowInvestModal(row)} />
        }
    ];

    const CLOSED_FUNDS_COLUMN = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '575px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-12px">
                        <span className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white">{row['name']}</span>
                        <span className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                            {row['type']}
                        </span>
                    </div>

                </div>
        },
        {
            id: 2,
            name: 'Launch date',
            selector: row => row['start_date'],
            sortable: true,
            left: true,
            sortFunction: (a, b) => _sortReturns(a, b, 'start_date'),
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['start_date']}
                </p>
        },
        {
            id: 3,
            name: 'Closed date',
            selector: row => row['end_date'],
            sortable: true,
            left: true,
            sortFunction: (a, b) => _sortReturns(a, b, 'end_date'),
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['end_date']}
                </p>
        },
        {
            id: 4,
            name: '',
            selector: '',
            sortable: false,
            left: true,
            // cell: row =>
            //     <GradientButton label="Apply now"
            //         className="py-2 px-3"
            //         handleClick={() => setShowInvestModal(row)}/>
        }
    ];

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getNFOData();
    }, []);

    // listener for getting mf investment data
    useEffect(() => {
        if (Object.keys(showInvestmentModal).length !== 0) {
            const { date } = _getDayOfMonth();
            _getMFInvestmentSummary(showInvestmentModal.id, true, date)
                .then((response) => {
                    setMfInvestmentData(response);
                    setMountInvestModal(true)
                })
                .catch((response) => {
                    toast.error(response, {
                        type: "error",
                    });
                });

        }
    }, [showInvestmentModal]);

    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestmentModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    function _getNFOData() {
        let url = 'mf-data/nfo';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNfoData(response.data);
            }
            setApiLoader(false);
        })
    }

    function _getPaymentStatus(id) {
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setPaymentStatus(response.data.payment_status);
                setPaymentStatusText(response.data.payment_status_txt);
            }
        })

    }

    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }



    const _handleCardClick = (row) => {
        // navigator("/explore/fund", {
        //     state: {
        //         fund_id: row.id
        //     }
        // });
    }

    const Description = (props) => {
        return (
            <>
                {

                    <div className={`d-flex ${props.className}`}>
                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px">{props.title}&nbsp;</span>
                        <span className={`e-poppins-medium e-font-12 line-height-16px color-outer-space`}>
                            {props.symbol === true && <span className="e-inter-medium">₹</span>}
                            {props.description}
                        </span>
                    </div>
                }
            </>

        )
    }


    return (
        <>
            <div className="e-page-container margin-80px-bottom e-explore" id="explore-page">
                <InvestmentHeader />
                <BreadCrumb data={NEWS_NAVIGATION} />
                {
                    apiLoader === true ? (
                        <div className="row">
                            <CustomLoader />
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12 mt-4">
                                <SectionTitle title="On-going NFO"
                                    className="position-relative z-index-1 mb-0" />
                            </div>
                            {
                                screenWidth > 576 ?
                                    <div className="col-12 mt-4">
                                        <DataTable columns={FUNDS_COLUMN}
                                            data={nfoData.open}
                                            pagination={false}
                                            customStyles={CUSTOM_STYLES}
                                            defaultSortAsc={true}
                                            rowClick={(row) => _handleCardClick(row)}
                                            defaultSortFieldId={3}
                                        />
                                    </div>
                                    :
                                    <>
                                        {
                                            nfoData.open.map((item, key) => {
                                                return (
                                                    <div className={`border-bright-gray pb-3 ${key !== nfoData.open.length - 1 && 'border-bottom'}`} key={key}>
                                                        <div className="row mt-3">
                                                            <div className="col-12">
                                                                <div className="d-flex align-items-center gap-8px "
                                                                    onClick={() => _handleCardClick(item)}>
                                                                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                                                                        <img src={item.image} alt={item.name}
                                                                            width={24}
                                                                            height={24}
                                                                            className="object-fit-contain" />
                                                                    </div>
                                                                    <div className="w-100">
                                                                        <span className="color-black e-poppins-medium e-font-12 line-height-18px mb-0 bg-white">{item.name}</span>
                                                                        <div className="d-flex justify-content-between w-100 mt-1">

                                                                            <span className="border-radius-16px border-all border-bright-gray d-block h-20px padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px w-fit-content">
                                                                                {item.type}
                                                                            </span>
                                                                            {/* <p className="e-primary-font-medium e-font-12 cursor-pointer w-max-content color-ocean-blue mb-0 pb-0 line-height-12px d-block text-decoration-underline"
                                                                                onClick={(e) => { e.stopPropagation(); setShowInvestModal(item) }}>Apply now</p> */}
                                                                            <GradientButton label="Apply now"
                                                                                className="py-2 px-3  e-font-10"
                                                                                handleClick={(e) =>{ e.stopPropagation(); setShowInvestModal(item)}} />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-2">
                                                                <Description title="Launch date:" description={item.start_date} />
                                                            </div>
                                                            <div className="col-12 mt-2">
                                                                <Description title="Closing date:" description={item.end_date} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }


                            <div className="col-12 mt-4">
                                <SectionTitle title="Closed NFO"
                                    className="position-relative z-index-1 mb-0" />
                            </div>
                            {
                                screenWidth > 576 ?
                                    <div className="col-12 mt-4">
                                        <DataTable columns={CLOSED_FUNDS_COLUMN}
                                            data={nfoData.closed}
                                            pagination={false}
                                            customStyles={CUSTOM_STYLES}
                                            defaultSortAsc={true}
                                            rowClick={(row) => _handleCardClick(row)}
                                            defaultSortFieldId={3}
                                        />
                                    </div>
                                    :
                                    <>
                                        {
                                            nfoData.open.map((item, key) => {
                                                return (
                                                    <div className={`border-bright-gray pb-3 ${key !== nfoData.open.length - 1 && 'border-bottom'}`} key={key}>
                                                        <div className="row mt-3">
                                                            <div className="col-12">
                                                                <div className="d-flex align-items-center gap-8px "
                                                                    onClick={() => _handleCardClick(item)}>
                                                                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                                                                        <img src={item.image} alt={item.name}
                                                                            width={24}
                                                                            height={24}
                                                                            className="object-fit-contain" />
                                                                    </div>
                                                                    <div className="d-flex flex-column gap-6px">
                                                                        <span className="color-black e-poppins-medium e-font-12 line-height-24px mb-0 bg-white">{item.name}</span>
                                                                        <span className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px w-fit-content">
                                                                            {item.type}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <Description title="Launch date:" description={item.start_date} />
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <Description title="Closed date:" description={item.end_date} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }


                        </div>
                    )
                }
            </div>
            <Footer />
            {
                mountInvestModal === true && (
                    <InvestGoalModal data={showInvestmentModal}
                        mfInvestmentData={mfInvestmentData}
                        close={() => {
                            setShowInvestModal({})
                            setMfInvestmentData({})
                            setMountInvestModal(false)
                        }}
                        getPaymentStatus={_getPaymentStatus} />
                )
            }
            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        close={() => setOpenPaymentStatusModal(false)} />
                )
            }
        </>
    )
};