/*
 *   File : navigation-item.js
 *   Author URI : www.evoqins.com
 *   Description : Navigation items
 *   Integrations : js-cookie
 *   Version : v1.0
 *   Created : 24-08-2023
 */

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import { useDispatch, useSelector } from "react-redux";
import { updateNavigation } from "../../Store/Action/action";
import { logOut } from "../../Helper";
import Store from "../../Store";

// import styles
import style from '../../Styles/Component/navigation-item.module.scss'

const DASHBOARD_OPTIONS = [
    {
        name: "Dashboard",
        link: "/",
        image_color: require("../../Assets/Images/Sidebar/dashboard-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/dashboard-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Portfolio",
        link: "/portfolio",
        image_color: require("../../Assets/Images/Sidebar/portfolio-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/portfolio-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Invest in Goals",
        link: "/goal",
        image_color: require("../../Assets/Images/Sidebar/invest-in-goals-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/invest-in-goals-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Explore",
        link: "/explore",
        image_color: require("../../Assets/Images/Sidebar/explore-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/explore-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Watchlist",
        link: "/watchlist",
        image_color: require("../../Assets/Images/Sidebar/your-watchlist-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/your-watchlist-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Orders",
        link: "/orders",
        image_color: require("../../Assets/Images/Sidebar/orders-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/orders-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Manage SIPs",
        link: "/sip",
        image_color: require("../../Assets/Images/Sidebar/manage-sips-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/manage-sips-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Manage STP & SWP",
        link: "/manage-stp-swp",
        image_color: require("../../Assets/Images/Sidebar/swp&stp-active.png"),
        image_gray: require("../../Assets/Images/Sidebar/swp&stp.png"),
        show_in_large_screen: true,
    },
    {
        name: "Reports",
        link: "/reports",
        image_color: require("../../Assets/Images/Sidebar/report-active.png"),
        image_gray: require("../../Assets/Images/Sidebar/reports-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Profile",
        link: "/profile",
        image_color: require("../../Assets/Images/Sidebar/account-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/account-gray.png"),
        show_in_large_screen: true,
    },
    {
        name: "Cart",
        link: "/cart",
        image_color: require('../../Assets/Images/dashboard/cart.png'),
        image_gray: require('../../Assets/Images/Sidebar/cart-inactive.svg').default,
        show_in_large_screen: false,
    },
    {
        name: "Settings",
        link: "/settings",
        image_color: require('../../Assets/Images/dashboard/setting-img.png'),
        image_gray: require('../../Assets/Images/Sidebar/settings-inactive.svg').default,
        show_in_large_screen: false,
    },
    {
        name: "Notifications",
        link: "/notifications",
        image_color: require('../../Assets/Images/dashboard/notification-img.png'),
        image_gray: require('../../Assets/Images/Sidebar/notification-inactive.svg').default,
        show_in_large_screen: false,
    },

];

const NON_INVESTED_TABS = DASHBOARD_OPTIONS.filter(tab => tab.name !== "Reports");
//  DASHBOARD_OPTIONS.filter(tab => tab.name !== "Reports");


// logout function
function _logout() {
    logOut();
}

const NavigationItem = () => {
    const windowWidth = window.innerWidth;
    const location = useLocation();
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const NAVIGATION = useSelector(state => state.generalData.path);
    const NOTIFICATION_COUNT = useSelector(state => state.generalData.NOTIFICATION_COUNT);
    //  useSelector(state => state.generalData.NOTIFICATION_COUNT); // notifications
    const CART = useSelector((state) => state.generalData.CART);
    const NAVIGATION_TABS = USER_DATA.is_invested === true ? DASHBOARD_OPTIONS : NON_INVESTED_TABS;

    const ACTIVE_MF_FILTERS = useSelector(state => state.generalData.MF_FILTERS);

    // updating the redux variable on path change 
    useEffect(() => {
        dispatch(updateNavigation(location.pathname));
    }, [location.pathname, dispatch]);

    // redirection
    const _handleNavigation = (link) => {
        navigator(link);
    };

    function _navigateHome() {
        if (location.pathname === '/') {
            window.scrollTo({
                top: '0',
                behavior: 'smooth'
            })
        } else {
            navigator('/');
        }

    }

    // prevent unwanted re render when redux update;
    const memoizedNavigationItems = useMemo(() => {
        // Matching the detail page
        const route_nodes = NAVIGATION.split("/");

        // if array length more than 2 means detail page taking the 1st node from url 
        const index_page = (route_nodes.length >= 2) ? `/${route_nodes[1]}` : NAVIGATION;

        return (
            NAVIGATION_TABS.map((item, key) => {
                if (windowWidth > 992 && item.show_in_large_screen === false) {
                    return;
                }
                return (
                    <div key={key}
                        className={`e-font-16-xl-14 d-flex align-items-center gap-8px py-2 padding-10px-lr margin-12px-bottom cursor-pointer white-space-nowrap position-relative
                        ${item.link === index_page ?
                                `color-charleston-green e-poppins-semi-bold  border-left-2px ` :
                                `color-black e-poppins-regular ${style.e_navigation_items}`
                            }
                        `}
                        onClick={() => _handleNavigation(item.link)}>
                        <img draggable={false}
                            src={
                                item.link === index_page
                                    ? item.image_color
                                    : item.image_gray
                            }
                            width={24}
                            height={24}
                            alt={item.name} />
                        <div className="d-flex align-items-center">
                            <p className="mb-0">{item.name}</p>
                            {
                                item.name === 'Cart' && Object.keys(CART).length !== 0 && CART.data.length !== 0 &&
                                <p className='color-white  e-poppins-bold e-font-10 line-height-12px e-badge mb-0 ms-2 bg-sea-green d-flex justify-content-center align-items-center'>
                                    {CART.data.length > 99 ? "99+" : CART.data.length}
                                </p>
                            }
                            {
                                item.name === 'Notifications' && NOTIFICATION_COUNT > 0 &&
                                <p className='color-white e-poppins-bold e-font-10 line-height-12px e-badge ms-2 mb-0 bg-sea-green d-flex justify-content-center align-items-center'>
                                    {NOTIFICATION_COUNT < 100 ? NOTIFICATION_COUNT : '99+'}
                                </p>}
                        </div>
                    </div>
                );
            })
        )
    }, [NAVIGATION_TABS, NAVIGATION]);

    return (
        <div className="e-menu-border border-right-1px bg-alice-blue position-sticky top-0 h-sm-100vh overflowY-auto overflowX-hidden overflowY-auto e-side-bar e-sidebar position-relative" >
            <div className="d-flex align-items-center justify-content-xl-center justify-content-lg-center justify-content-ms-start padding-24px-top margin-20px-bottom e-sidebar-logo e-zoom-out-wrapper">
                <img draggable={false}
                    src={require("../../Assets/Images/master-trust-logo.svg").default}
                    alt="Master Trust logo"
                    className="cursor-pointer"
                    onClick={_navigateHome} />
            </div>

            <div className={`${style.e_menu_item_wrapper} position-relative left-0 right-0 margin-auto e-zoom-out-wrapper e-sidebar-menu-wrapper e-nav-items`}>
                {/* navigation items */}
                {memoizedNavigationItems}
                <div className={`color-black e-poppins-regular e-font-16 d-flex align-items-center gap-8px py-2 padding-10px-lr  cursor-pointer ${style.e_navigation_items}`}
                    onClick={_logout}>
                    <img draggable={false}
                        src={require('../../Assets/Images/Sidebar/logout-gray.png')}
                        width={24}
                        height={24}
                        alt="Logout" />
                    <p className="mb-0">Logout</p>
                </div>
            </div>
        </div>
    )
}

export default NavigationItem;