/*
 *   File : personal-details.js
 *   Author URI : https://evoqins.com
 *   Description :   Component which allows the user to add personal details to their KYC verification
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { Tooltip } from "react-tooltip";

import { CustomFilePicker, CustomTextInput, FilePreview, RadioGroup } from "../../../Component/Form-elements"
import { CustomLoader } from "../../../Component/Other";
import { GradientButton } from "../../../Component/CTA";
import Icon from "../../../Component/Icon/icon";
import { _convertToLabelIdArray, _isFileTypeAllowed } from "../../../Helper";
import { _uploadDocumentToS3 } from "../../../Service/aws-service";
import APIService from "../../../Service/api-service";
import { _getKycProfile } from "../../../Helper/api";
import { SignaturePreviewModal } from "../../../Component/Modal";
import { Modal } from "bootstrap";
import { CustomToolTip } from "../../../Component/Popups";

const PersonalDetailsPage = (props) => {

    const GENERAL_TYPES = useSelector(state => state.generalData.KYC_GENERAL_DATA);
    const PERSONAL_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA.additional_info);
    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);

    const [relationData, setRelationData] = useState([]);
    const [selectedRelation, setSelectedRelation] = useState(null);
    const [loader, setLoader] = useState(true);
    const [fatherName, setFatherName] = useState("");
    const [fatherNameError, setFatherNameError] = useState("");
    const [genderData, setGenderData] = useState([]);
    const [selectedGender, setSelectedGender] = useState(null);
    const [maritalData, setMaritalData] = useState([]);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
    const [occupationData, setOccupationData] = useState([]);
    const [selectedOccupation, setSelectedOccupation] = useState(null);
    const [annualIncomeData, setAnnualIncomeData] = useState([]);
    const [selectedAnnualIncome, setSelectedAnnualIncome] = useState(null);
    const [showInfo, setShowInfo] = useState(false);

    const [file, setFile] = useState("");
    const [fileError, setFileError] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [fileLoader, setFileLoader] = useState(false);
    const [fileSize, setFileSize] = useState("")

    const [apiLoader, setApiLoader] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (Object.keys(GENERAL_TYPES).length !== 0) {

            if (PERSONAL_DATA.father_name !== null) {
                setFatherName(PERSONAL_DATA.father_name);
            }
            let gender = _convertToLabelIdArray(GENERAL_TYPES.gender);
            setGenderData(gender);
            if (PERSONAL_DATA.gender !== null) {
                setSelectedGender(PERSONAL_DATA.gender);
            }
            else {
                setSelectedGender(gender[0].id);
            }

            let annual_income = _convertToLabelIdArray(GENERAL_TYPES.income_slab);
            setAnnualIncomeData(annual_income);
            if (PERSONAL_DATA.income !== null) {
                setSelectedAnnualIncome(PERSONAL_DATA.income)
            }
            else {
                setSelectedAnnualIncome(annual_income[0].id)
            }

            let marital_status = _convertToLabelIdArray(GENERAL_TYPES.marital_status);
            setMaritalData(marital_status);
            if (PERSONAL_DATA.marital_status !== null) {
                setSelectedMaritalStatus(PERSONAL_DATA.marital_status);
            }
            else {
                setSelectedMaritalStatus(marital_status[0].id);
            }

            let occupation = _convertToLabelIdArray(GENERAL_TYPES.occupation)
            setOccupationData(occupation);
            if (PERSONAL_DATA.occupation !== null) {
                setSelectedOccupation(PERSONAL_DATA.occupation);
            }
            else {
                setSelectedOccupation(occupation[0].id);
            }

            let relation = _convertToLabelIdArray(GENERAL_TYPES.kyc_relation)
            setRelationData(relation);
            setSelectedRelation(relation[0].id);

            if (PERSONAL_DATA.signature_status !== null) {
                const file_name = PERSONAL_DATA.signature_status.substring(PERSONAL_DATA.signature_status.lastIndexOf('/') + 1, PERSONAL_DATA.signature_status.indexOf('?'));
                setFile(file_name)
                setFileUrl(PERSONAL_DATA.signature_status)
            }
            setLoader(false);
        }
    }, [GENERAL_TYPES, PERSONAL_DATA])

    useEffect(() => {
        if (showPreview === true) {
            const modal = new Modal(document.getElementById("signature-preview"), {});
            modal.show();
        }
    }, [showPreview])

    const _handleInputFields = (type, data) => {
        switch (type) {
            case 1:
                setSelectedRelation(data);
                break;
            case 2:
                setFatherName(data);
                setFatherNameError("");
                break;
            case 3:
                setSelectedGender(data);
                break;
            case 4:
                setSelectedMaritalStatus(data);
                break;
            case 5:
                setSelectedOccupation(data);
                break;
            case 6:
                setSelectedAnnualIncome(data);
                break;
        }
    }

    const _handleFileUpload = (value, file) => {
        //eslint-disable-next-line
        var file_formatted = value.replace(/^.*[\\\/]/, '').split('/');
        //eslint-disable-next-line
        file_formatted = file_formatted[0].replace(/[\(\)\s]/g, '_');
        console.log("File", file);
        console.log("Value", value)
        console.log("File formatted", file_formatted);
        const parts = file_formatted.split('.');
        if (parts.length > 1) {
            const extension = '.' + parts.pop();
            if (_isFileTypeAllowed(extension)) {
                // checking if file size is greater than 5mb
                const file_size = 5 * 1024 * 1024;
                if (file.size < file_size) {
                    _fileUpload(file_formatted, file);
                    setFileSize(file_size);
                } else {
                    setFileError("File size should be less than 5mb");
                }
            } else {
                setFileError("File type should be .pdf, .jpeg, .jpg, .png");
            }
            return extension;
        } else {
            setFileError("No file extension found");
            return ''; // No extension found
        }
    }


    const _validateForm = () => {
        if (fatherName.length === 0) {
            setFatherNameError("Enter father name");
            _scrollToTop();
        } else if (fileUrl.length === 0) {
            setFileError("File is required");
        } else {
            setApiLoader(true);
            _savePersonalData();
        }
    };

    const _scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const _handleMouseEnter = () => {
        setShowInfo(true);
    }

    const _handleMouseLeave = () => {
        setShowInfo(false);
    }

    const _handlePreview = () => {
        setShowPreview(true);
    }

    //API :: file upload api
    const _fileUpload = (key, file) => {
        setFileError("");
        setFileLoader(true);
        let url = "general/file-upload";
        let body = {
            "key": `support/${key}`
        }

        APIService(true, url, body).then((response) => {
            if (response.status_code === 200) {
                setFileLoader(true);

                // s3 helper
                _uploadDocumentToS3(response.data, key, file).then((result) => {
                    setFileUrl(response.data.view_info); // file got uploaded successfully
                    
                    console.log("KEY", key)
                    setFile(key);
                    setFileLoader(false);
                }).catch(error => {
                    toast.dismiss();
                    toast.error(error, {
                        type: "error",
                    });
                    setFileLoader(false);
                });

            } else {
                setFileLoader(false);
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            }
        })
    }

    async function _savePersonalData() {
        let url = 'kyc/save-personal';
        let payload = JSON.stringify({
            relation_id: selectedRelation,
            father_name: fatherName,
            gender_id: selectedGender,
            marital_status_id: selectedMaritalStatus,
            occupation_id: selectedOccupation,
            income_id: selectedAnnualIncome,
            sign_proof: fileUrl
        });

        try {
            const response = await APIService(true, url, payload);

            if (response.status_code === 200) {
                const kyc_data = await _getKycProfile();
                props.updateProgress(5);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error.message, {
                type: "error",
            });
        }
        finally {
            setApiLoader(false);
        }
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="pb-sm-0 pb-3">
                <p className="color-black e-poppins e-poppins-bold e-font-24-sm-16 mb-2">Share a bit about yourself</p>
                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">
                    Share a few more personal information to know about your investment preference.
                </p>

                {/* Relation */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 margin-32px-top">Relation</p>
                <RadioGroup data={relationData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedRelation}
                    radioHandler={(id) => _handleInputFields(1, id)} />

                {/* Father input field */}
                <div className="row margin-26px-top-sm-16">
                    <div className="col-lg-4 col-md-6 col-12">
                        <CustomTextInput label="Father name"
                            postfix="*"
                            value={fatherName}
                            error={fatherNameError}
                            handleChange={(data) => _handleInputFields(2, data)} />
                    </div>
                </div>

                {/* Gender */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-3">Gender</p>
                <RadioGroup data={genderData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedGender}
                    radioHandler={(id) => _handleInputFields(3, id)} />

                {/* Marital status */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Marital status</p>
                <RadioGroup data={maritalData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedMaritalStatus}
                    radioHandler={(id) => _handleInputFields(4, id)} />

                {/* Occupation */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Your occupation</p>
                <RadioGroup data={occupationData}
                    className={`gap-16px mt-3`}
                    type={3}
                    id={selectedOccupation}
                    radioHandler={(id) => _handleInputFields(5, id)} />

                {/* Annual income */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Your annual income</p>
                <RadioGroup data={annualIncomeData}
                    className={`gap-16px mt-3`}
                    type={2}
                    id={selectedAnnualIncome}
                    radioHandler={(id) => _handleInputFields(6, id)} />

                {/* Digital signature */}
                <p className="color-black e-poppins-medium e-font-18-sm-16 mt-4">Upload digital signature</p>

                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px border-radius-8px bg-beige w-fit-content p-3 mb-4">
                    *Sign on a blank white paper using blue ink, take a clear photo of it and upload
                </p>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-4 col-md-6 col-10">
                        {
                            file.length === 0 ?
                                <CustomFilePicker accept=".png, .pdf, .jpeg "
                                    className="e-select-file"
                                    error={fileError}
                                    loading={fileLoader}
                                    onSelectFile={(path, file) => _handleFileUpload(path, file)} />
                                :
                                <FilePreview fileName={file}
                                    fileUrl={fileUrl}
                                    fileSize={fileSize}
                                    preview={true}
                                    previewFile={_handlePreview}
                                    removeFile={() => {
                                        setFile("");
                                        setFileUrl("");
                                        setFileSize("")
                                    }} />
                        }
                    </div>
                    <div className="col-lg-4 col-md-6 col-2">
                        {
                            Object.keys(TOOL_TIP).length !== 0 &&
                            <div className="position-relative">
                                <Icon icon="info"
                                    size={24}
                                    className="cursor-pointer"
                                    data-tooltip-id="sign-tooltip" />
                                <Tooltip id="sign-tooltip"
                                    arrowColor='transparent'
                                    effect="float"
                                    place="bottom"
                                    className='e-tooltip-general'>
                                    {parse(TOOL_TIP?.kyc_sign_upload)}
                                </Tooltip>
                            </div>
                        }

                    </div>
                </div>

                <GradientButton label="Continue"
                    loading={apiLoader}
                    className="px-3 padding-12px-tb e-font-16 margin-40px-top-sm-16 mob-w-100"
                    handleClick={_validateForm} />

                {
                    showPreview === true &&
                    <SignaturePreviewModal file={fileUrl}
                        close={() => setShowPreview(false)} />
                }

            </div>
    )
}

export default PersonalDetailsPage