/*
 *   File : goal-portfolio-detail.js
 *   Author URI : https://evoqins.com
 *   Description : Detail page for invested goal
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Modal } from "bootstrap"

import { BreadCrumb } from "../../Component/BreadCrumb"
import { GradientButton, OutlineButton } from "../../Component/CTA"
import { DataTable } from "../../Component/Form-elements"
import { InvestmentHeader } from "../../Component/Header"
import Icon from "../../Component/Icon/icon"
import Footer from "../../Component/Footer/footer"
import { CustomTab } from "../../Component/Tab"
import { _getPortFolioDetail } from "../../Helper/api"
import { GoalRedeemModal, InvestGoalModal, PaymentStatusModal } from "../../Component/Modal"
import APIService from "../../Service/api-service"
import { _getGoalInvestmentSummary } from "../../Helper/api";
import { CustomLoader } from "../../Component/Other"
import { _getColorForInvestmentStyle, _getDayOfMonth } from "../../Helper"
import Colors from "../../Styles/color.module.scss";

const MF_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Portfolio', url: '/portfolio' },
    { page: 'Investment detail', url: null }
]

const TAB_DATA = ['Invested funds & allocations', 'Transaction history'];

const INVESTED_CUSTOM_STYLES = {
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    headRow: {
        style: {
            paddingBottom: "8px !important",
        }
    },
    rows: {
        style: {
            padding: "1rem",
        },
    },
    cells: {
        style: {
            color: Colors.black,
            fontFamily: "Poppins-Medium",
            fontSize: "0.875rem",
            fontWeight: "500",
            lineHeight: "1.125rem",
            justifyContent: "center",
        }
    }
};

const TRANSACTION_CUSTOM_STYLES = {
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    headRow: {
        style: {
            paddingBottom: "8px !important",
        }
    },
    rows: {
        style: {
            padding: "1.5rem 1rem",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            color: Colors.black,
            fontFamily: "Poppins-Medium",
            fontSize: "1rem",
            fontWeight: "500",
            lineHeight: "1.125rem",
            justifyContent: "center",
        }
    }
};

const SUCCESS_MESSAGE = {
    title: 'Redeem successful',
    message: 'You have successfully redeemed'
}

const FAILED_MESSAGE = {
    title: 'Redeem failed',
    body: 'We regret to inform you that your redemption was failed.'
}

const GoalPortfolioDetail = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showArrow, setShowArrow] = useState(0);
    const [tabIndex, setTabIndex] = useState(1);
    const [loader, setLoader] = useState(true);
    const [portFolioDetail, setPortFolioDetail] = useState({});
    const [goalModalData, setGoalModalData] = useState({});
    const [apiLoader, setApiLoader] = useState(false);
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [goalData, setGoalData] = useState({});
    const [goalInvestmentData, setGoalInvestmentData] = useState({});
    const [investmentType, setInvestmentType] = useState(1);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [goalDetails, setGoalDetails] = useState({});
    const [colour, setColour] = useState('#FCD34D');
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            // API - get portfolio details
            _getPortFolioDetail(location.state.id, location.state.is_goal)
                .then((response) => {
                    setPortFolioDetail(response);
                    setLoader(false);


                })
                .catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error",
                    });
                    setLoader(false);
                });
        }
        else {
            navigate("/portfolio");
        }
    }, [location])

    useEffect(() => {
        if (Object.keys(goalModalData).length !== 0) {
            const modal = new Modal(document.getElementById("goal-redeem"), {});
            modal.show();
        }
    }, [goalModalData]);

    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestGoalModal();
        }
    }, [mountInvestModal]);


    //to open invest goal modal
    const _openInvestGoalModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    };

    useEffect(() => {
        if (paymentStatus === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [paymentStatus]);


    const TRANSACTION_COLUMN = [
        {
            name: 'Date',
            selector: row => row['created'],
            sortable: true,
        },
        {
            name: 'Trans. type',
            selector: row => row['transaction_type'],
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row['order_type'],
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: true,
            cell: row => row.amount > 0 ?
                <p className="mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>
                    {row['amount'].toLocaleString('en-IN')}</p> :
                <p className="mb-0"
                    onClick={() => _handleCardClick(row)}>-</p>
        },
        {
            name: 'Status',
            selector: row => row['order_status'],
            sortable: true,
            cell: row =>
                row['order_status'] === "Processing" ?
                    <div className="d-flex align-items-center justify-content-center"
                        onClick={() => _handleCardClick(row)}>
                        <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                            {row['order_status']}
                        </p>
                        <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                            width={24}
                            height={24}

                            draggable={false} />
                    </div>
                    :

                    row['order_status'] === "Success" ?
                        <div className="d-flex align-items-center justify-content-center "
                            onClick={() => _handleCardClick(row)}>
                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                {row['order_status']}
                            </p>
                            <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                width={24}
                                height={24}
                                draggable={false} />
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center"
                            onClick={() => _handleCardClick(row)}>
                            <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                {row['order_status']}
                            </p>
                            <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                width={24}
                                height={24}
                                draggable={false} />
                        </div>
        },
        {
            name: '',
            sortable: true,
            width: "48px",
            cell: row =>

                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const INVESTED_FUND_COLUMN = [
        {
            name: 'Invested funds',
            selector: row => row['fund_name'],
            sortable: true,
            width: "250px",
            cell: row => <div className="d-flex align-items-center gap-12px">
                <div className="border-all border-bright-gray border-radius-8px p-2 w-unset">
                    <img src={row['image']}
                        alt={row['fund_name']}
                        width={36}
                        height={36} />
                </div>
                <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-0">{row['fund_name']}</p>
            </div>
        },
        {
            name: 'Current weightage',
            selector: row => row['allocation'],
            sortable: true,
            cell: row => <p className="mb-0">{row['allocation']}%</p>
        }, {
            name: 'Invested',
            selector: row => row['invested_amount'],
            sortable: true,
            cell: row => <p className="mb-0"><span className="e-inter-medium">₹</span>{row['invested_amount'].toLocaleString("en-IN")}</p>
        }, {
            name: 'Current',
            selector: row => row['current_amount'],
            sortable: true,
            cell: row => <p className="mb-0"><span className="e-inter-medium">₹</span>{row['current_amount'].toLocaleString("en-IN")}</p>
        }, {
            name: 'Units',
            selector: row => row['units'],
            sortable: true,
        }, {
            name: 'Current returns',
            selector: row => row['returns'],
            sortable: true,
            cell: row =>
                <p className={`${row['returns'] < 0 ? "color-red" : "color-sea-green"} e-inter-medium e-font-14 line-height-18px mb-0`}
                    onClick={() => _handleCardClick(row)}>
                    {
                        row['returns'].toLocaleString("en-IN") == 0 ? '-'
                            :
                            <>
                                ₹{row['returns'].toLocaleString("en-IN")}
                                ({row['returns_percentage'].toLocaleString("en-IN")}%)
                            </>
                    }
                </p>
        }
    ];


    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    // handler for tab change
    const _handleTabSelect = (index) => {
        setTabIndex(index);
    }

    const _handleCardClick = (row) => {
        navigate("/orders/goal-order-summary", { state: { order_id: row['id'] } });
    }


    // API:: get payment status after investment 
    function _getPaymentStatus() {
        setPaymentStatus(true);
    }

    const _handleRedeemStatus = (type) => {
        setGoalModalData({});
        setShowRedeemModal(false)
        setPaymentStatus(true);
        switch (type) {
            case 1:
                setPaymentMessage(SUCCESS_MESSAGE)
                break;
            case 2:
                setPaymentMessage(FAILED_MESSAGE)
                break;
        }
    }

    useEffect(() => {
        if (Object.keys(goalDetails).length !== 0) {
            const modal = new Modal(document.getElementById("goal-redeem"), {});
            modal.show();
        }
    }, [goalDetails]);

    // API - track redeem drop off user
    function _saveRedeemUser() {
        let url = 'portfolio/save-redemption-request';
        let payload = JSON.stringify({
            id: location.state.id,
            is_goal: true
        });
        APIService(true, url, payload).then((response) => {
            console.log(response);
        })
    }

    // API - Get redeem data
    const _handleRedeem = () => {
        if (portFolioDetail.investment_details.type === 'mt' && portFolioDetail.investment_details.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            _saveRedeemUser();
            return;
        }
        setApiLoader(true);
        let url = 'goal/redeem-data';
        let payload = JSON.stringify({
            id: portFolioDetail.investment_details.id,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setGoalDetails(response.data);
                setShowRedeemModal(true);
                setColour(_getColorForInvestmentStyle(response.data.investment_style))
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - Get goal data 
    const _getGoalData = () => {
        const { date } = _getDayOfMonth();

        let url = 'goal/details';
        let payload = JSON.stringify({
            goal_id: portFolioDetail.investment_details.id,
            is_invested: true
        });
        APIService(true, url, payload).then((response) => {
            console.log('Response :: ', response)
            if (response.status_code === 200) {
                setGoalModalData(response.data);
                setMountInvestModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });

    }

    const FundDetail = (props) => {
        return (
            <div className="d-flex align-items-center">
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0 me-1">{props.heading}:</p>
                <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">
                    {props.rupee && <span className="e-inter-medium">₹</span>}{props.description}
                </p>
                {
                    (props.returns != undefined && props.returns != 0) &&
                    <p className={`${props.returns < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-16 line-height-16px mb-0`}>({props.returns}%)</p>
                }

            </div>
        )
    }

    const Description = (props) => {
        return (
            <>
                {

                    <div className={`d-flex ${props.className}`}>
                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px">{props.title}&nbsp;</span>
                        <span className={`e-poppins-medium e-font-12 line-height-16px color-outer-space`}>
                            {props.symbol === true && <span className="e-inter-medium">₹</span>}
                            {props.description}
                        </span>
                    </div>
                }
            </>

        )
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <div className="e-fund-portfolio-detail e-page-container margin-72px-bottom">
                    <InvestmentHeader />
                    <BreadCrumb data={MF_NAVIGATION} />
                    <p className="color-black e-poppins-medium e-font-20 line-height-32px my-4">Investments details</p>
                    <div className="border-radius-24px border-all border-bright-gray p-4">
                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-16px padding-26px-bottom border-bottom-1px border-bright-gray">
                            <div className="d-flex align-items-center gap-12px">
                                {/* goal icon */}
                                <div className="p-2 border-radius-8px border-all border-bright-gray">
                                    <img src={portFolioDetail.investment_details.image}
                                        alt={portFolioDetail.investment_details.goal}
                                        className="object-fit-contain"
                                        width={40}
                                        height={40} />
                                </div>
                                {/* goal name and risk profiler */}
                                <div>
                                    <p className="color-black e-poppins-medium e-font-16 line-height-16px margin-12px-bottom">{portFolioDetail.investment_details.goal}</p>
                                    <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">Investment style: <span className="color-primary-color" style={{ color: colour }}>{portFolioDetail.investment_details.investment_style}</span></p>
                                </div>
                            </div>

                            {/* units and invested amount */}
                            <div className="d-flex flex-wrap gap-24px">
                                <FundDetail heading="Total Units"
                                    description={portFolioDetail.investment_details.units}
                                    rupee={false} />
                                <FundDetail heading="Total invested"
                                    description={portFolioDetail.investment_details.invested_amount.toLocaleString("en-IN")}
                                    rupee={true} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-24px mt-3">
                            <div className="d-flex flex-wrap gap-24px">
                                <FundDetail heading="Current amount"
                                    description={portFolioDetail.investment_details.current_amount.toLocaleString("en-IN")}
                                    rupee={true} />
                                <FundDetail heading="Returns"
                                    description={portFolioDetail.investment_details.returns}
                                    rupee={true}
                                    returns={portFolioDetail.investment_details.returns_percentage} />
                            </div>

                            <div className="d-flex gap-16px align-items-center">
                                {/* <OutlineButton label="Start new SIP"
                                    className="py-2 px-4 e-font-16" 
                                    handleClick= {_getGoalData} /> */}
                                <OutlineButton label="Redeem"
                                    className="padding-12px-all e-font-16 color-black w-150"
                                    loading={apiLoader}
                                    handleClick={_handleRedeem} />
                                {/* <GradientButton label="Invest more"
                                    className="px-3 py-2 e-font-16" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="e-tap-wrapper">
                        <CustomTab data={TAB_DATA}
                            wrapperClass={'gap-16px'}
                            className="px-0 padding-10px-tb e-order-tab mt-3 margin-28px-bottom"
                            onSelectTab={_handleTabSelect} />
                    </div>
                    <div className="col-12 text-end mt-2 d-sm-block d-none e-table-scroll-indicator-watchlist">
                        <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                            <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                width='24px'
                                height='24px'
                                className='e-rotate-gif' />
                            Scroll horizontally for view more  information</p>
                    </div>

                    {
                        tabIndex === 1 ?
                            <>
                                {
                                    screenWidth > 576 ?
                                        <DataTable columns={INVESTED_FUND_COLUMN}
                                            data={portFolioDetail.funds}
                                            key="Invested fund allocation"
                                            pagination={false}
                                            customStyles={INVESTED_CUSTOM_STYLES} />
                                        :
                                        <>
                                            {
                                                portFolioDetail.funds.map((item, key) => {
                                                    return (
                                                        <div className=" border-bottom border-bright-gray py-3" key={key}>
                                                            <div className="d-flex align-items-center gap-8px">
                                                                <div className="border-all border-bright-gray border-radius-8px p-2 w-unset">
                                                                    <img src={item.image}
                                                                        alt={item.fund_name}
                                                                        width={24}
                                                                        height={24} />
                                                                </div>
                                                                <p className="color-black e-poppins-medium e-font-12 line-height-20px mb-0">{item.fund_name}</p>
                                                            </div>
                                                            <div className="row mt-3 gx-0">
                                                                <div className="col-6 pe-0">
                                                                    <Description title="Current weightage :" description={item.allocation} />
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="d-flex">
                                                                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Invested:</span>
                                                                        {
                                                                            item.invested_amount > 0 ?
                                                                                <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                    onClick={() => _handleCardClick(item)}>
                                                                                    <span className="e-inter-medium">₹</span>
                                                                                    {item.invested_amount.toLocaleString('en-IN')}</p> :
                                                                                <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                    onClick={() => _handleCardClick(item)}>-</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-6 text-end">
                                                                    <div className="d-flex">
                                                                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Current:</span>
                                                                        {
                                                                            item.current_amount > 0 ?
                                                                                <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                    onClick={() => _handleCardClick(item)}>
                                                                                    <span className="e-inter-medium">₹</span>
                                                                                    {item.current_amount.toLocaleString('en-IN')}</p> :
                                                                                <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                    onClick={() => _handleCardClick(item)}>-</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="col-6 pe-0">
                                                                        <Description title="Units :" description={item.units} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-12 text-end">
                                                                    <div className="d-flex">
                                                                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Current returns:</span>
                                                                        <p className={`${item.returns < 0 ? "color-red" : "color-sea-green"} e-inter-medium e-font-12 line-height-18px mb-0`}
                                                                            onClick={() => _handleCardClick(item)}>
                                                                            {
                                                                                item.returns.toLocaleString("en-IN") == 0 ? '-'
                                                                                    :
                                                                                    <>
                                                                                        ₹{item.returns.toLocaleString("en-IN")}
                                                                                        ({item.returns_percentage.toLocaleString("en-IN")}%)
                                                                                    </>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                }

                            </>
                            :
                            <>
                                {
                                    screenWidth > 576 ?
                                        <DataTable columns={TRANSACTION_COLUMN}
                                            data={portFolioDetail.orders}
                                            pagination={false}
                                            key="Transaction history"
                                            customStyles={TRANSACTION_CUSTOM_STYLES}
                                            onRowMouseEnter={_handleRowHover}
                                            onRowMouseLeave={_handleRowRemoveHover}
                                            rowClick={_handleCardClick} />
                                        :
                                        <>
                                            {
                                                portFolioDetail.orders.map((item, key) => {
                                                    return (
                                                        <div className=" border-bottom border-bright-gray py-3" key={key}>
                                                            <div className="row  gx-0">
                                                                <div className="col-4 pe-0">
                                                                    <Description title="Date :" description={item.created} />
                                                                </div>
                                                                <div className="col-5">
                                                                    <Description title="Trans. type:" description={item.transaction_type} />
                                                                </div>
                                                                <div className="col-3 text-end">
                                                                    <Description title="NAV :" description={item.nav ? item.nav : '-'}
                                                                        className="justify-content-end" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 gx-0">
                                                                <div className="col-4 pe-0">
                                                                    <Description title="Type:" description={item.order_type} />
                                                                </div>
                                                                <div className="col-4 pe-0">
                                                                    <div className="d-flex">
                                                                        <span className="color-gray e-poppins-medium e-font-12 line-height-16px me-1">Amount:</span>
                                                                        {
                                                                            item.amount > 0 ?
                                                                                <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                    onClick={() => _handleCardClick(item)}>
                                                                                    <span className="e-inter-medium">₹</span>
                                                                                    {item.amount.toLocaleString('en-IN')}</p> :
                                                                                <p className="color-black e-poppins-semi-bold e-font-12 line-height-16px mb-0 "
                                                                                    onClick={() => _handleCardClick(item)}>-</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-4 text-end">
                                                                    <Description title="Units :" description={item.units ? item.units : '-'}
                                                                        className="justify-content-end" />
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 text-start mt-2 pt-1">
                                                                    {
                                                                        item.order_status === 'Failed' ?
                                                                            <div className="d-flex align-items-center justify-content-start"
                                                                                onClick={() => _handleCardClick(item)}>
                                                                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                    {item.order_status}
                                                                                </p>
                                                                                <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                                                                    width={18}
                                                                                    height={18}
                                                                                    draggable={false} />
                                                                            </div>
                                                                            :
                                                                            item.order_status === "Success" ?
                                                                                <div className="d-flex align-items-center justify-content-start "
                                                                                    onClick={() => _handleCardClick(item)}>
                                                                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                        {item.order_status}
                                                                                    </p>
                                                                                    <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                                                                        width={18}
                                                                                        height={18}
                                                                                        draggable={false} />
                                                                                </div>
                                                                                :
                                                                                item.order_status === "Payment pending" ?
                                                                                    <div className="d-flex align-items-center justify-content-start"
                                                                                        onClick={() => _handleCardClick(item)}>
                                                                                        <p className="color-sunset-blaze e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                            {item.order_status}
                                                                                        </p>
                                                                                        <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                                                                            width={18}
                                                                                            height={18}
                                                                                            draggable={false} />
                                                                                    </div>
                                                                                    :
                                                                                    <div className="d-flex align-items-center justify-content-start"
                                                                                        onClick={() => _handleCardClick(item)}>
                                                                                        <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1 w-max-content">
                                                                                            {item.order_status}
                                                                                        </p>
                                                                                        <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                                                                            width={18}
                                                                                            height={18}
                                                                                            draggable={false} />
                                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </>

                    }
                </div>

                <Footer />

                {
                    showRedeemModal === true ?
                        <GoalRedeemModal data={goalDetails}
                            redeemStatus={_handleRedeemStatus}
                            close={() => {
                                setGoalModalData({});
                                setShowRedeemModal(false)
                            }} />
                        :
                        null
                }


                {/* Investment modal */}
                {
                    mountInvestModal === true &&
                    <InvestGoalModal investmentType={2}
                        goalId={goalDetails.goal_data.id}
                        goalSummary={goalDetails}
                        mfInvestmentData={goalDetails}
                        close={() => {
                            setMountInvestModal(false)
                        }}
                        getPaymentStatus={_getPaymentStatus} />
                }

                {/* payment status */}
                {
                    paymentStatus && (
                        <PaymentStatusModal
                            status={paymentStatus}
                            message={paymentMessage}
                            type={paymentMessage !== null && 2}
                            close={() => {
                                setPaymentMessage(null);
                                setPaymentStatus(false);
                            }}
                        />
                    )
                }



            </Fragment>
    )
}

export default GoalPortfolioDetail;