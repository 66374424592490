
/*
 *   File : start-investing.js
 *   Author URI : https://evoqins.com
 *   Description : Start investing banner
 *   Integrations : null
 *   Version : v1.1
 */
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from 'bootstrap';
import { useSelector } from 'react-redux';

import { GradientButton, OutlineButton } from "../CTA";
import { ImportExternalFundModal } from '../Modal';

import Color from "../../Styles/color.module.scss"

export default function StartInvesting(props) {

    const KYC_DONE = useSelector(state => state.generalData.PROFILE_DATA);
    const KYC_STEP = useSelector(state => state.generalData.KYC_PROGRESS_DATA);

    const navigator = useNavigate();
    const [browserZoom, setBrowserZoom] = useState(100);
    const [initiateExternalFunds, setInitiateExternalFunds] = useState(false);


    useEffect(() => {
        window.addEventListener("resize", getSizes);

        return () => {
            window.removeEventListener("resize", getSizes);
        };
    }, []);

    useEffect(() => {
        if (initiateExternalFunds === true) {
            const modal = new Modal(document.getElementById("initiate-external-funds-modal"), {});
            modal.show();
        }
    }, [initiateExternalFunds])

    const getSizes = () => {
        const new_zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
        setBrowserZoom(Math.ceil(new_zoom));
    };

    function _redirectInvest() {
        navigator('/explore');
    }

    const _handleImport = () => {
        setInitiateExternalFunds(true);
    }

    const _handleInitiateClose = () => {
        setInitiateExternalFunds(false);
    }


    function _navigate() {
        navigator('/portfolio', { state: { show_imported_fund: true } })
    }

    const _handleNavigate = () => {
        navigator("/kyc")
    }

    const KycBanner = (props) => {
        return (
            <div className='padding-40px-tb padding-40px-left border-radius-20px position-relative padding-40px-right-mob'
                style={{ backgroundColor: props.backgroundColor }}>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <p className='color-black e-inter-semi-bold e-font-32-sm-24 margin-12px-bottom e-kyc-title'>{props.heading}</p>
                        <p className='color-black e-poppins-medium e-font-sm-16-14 line-height-22px mb-4'>{props.description}</p>
                        <GradientButton label={props.label}
                            className="padding-12px-tb px-3 e-invest-btn-w-100"
                            handleClick={_handleNavigate} />
                    </div>
                </div>

                <img src={props.image}
                    alt={props.label}
                    height={224}
                    draggable={false}
                    className='object-fit-contain position-absolute e-kyc-decision-banner d-sm-block d-none' />

            </div>
        )
    }

    return (
        <Fragment>

            {
                KYC_DONE.is_kyc_done === true ?
                    <div className={`bg-ivory ps-4 pb-4 pe-lg-0 pe-md-0 pe-sm-0 pe-4 border-radius-24px border-bright-gray border-all `}>
                        <div className="row">
                            <div className="col-md-7 mb-2 pt-3">
                                <h2 className="color-black e-poppins-semi-bold e-font-32-sm-24 line-height-48px-sm-32px mt-4">
                                    Start investing today or Import your external funds
                                </h2>
                                <p className="color-black e-poppins-regular e-font-20-sm-14 line-height-34px-sm-24px padding-100px-right-sm-0">
                                    Complete your registration & start investing in just 7 minutes.
                                </p>
                                <GradientButton label='Start investing'
                                    className='padding-12px-all me-3 w-mobile-100'
                                    handleClick={_redirectInvest} />
                                {
                                    
                                    props.isCASImported !== true &&
                                    <OutlineButton label="Import external funds "
                                        icon="refresh-external-case"
                                        iconSize={24}
                                        className='e-import-fund w-mobile-100 mt-lg-0 mt-md-0 mt-sm-0 mt-3'
                                        handleClick={_handleImport} />
                                }

                            </div>
                            <div className="col-sm-5 text-end d-md-block d-none">
                                <img src={require('../../Assets/Images/dashboard/start-investing.png')}
                                    alt=''
                                    className="w-90" />
                            </div>
                        </div>
                    </div>
                    :
                    KYC_STEP.kyc_step > 9 ?
                        <KycBanner heading="KYC updation failed"
                            description="Due to some technical errors your KYC couldn't complete please update your KYC for better investing experience. "
                            label="Update KYC"
                            backgroundColor={Color.light_cream}
                            image={require("../../Assets/Images/kyc/update-kyc.png")} />
                        : KYC_STEP.kyc_step === 9 ?
                            <KycBanner heading="Your KYC under verification"
                                description="Currently undergoing KYC verification. Stay tuned for updates on the status."
                                label="View KYC"
                                backgroundColor={Color.pastel_blue}
                                image={require("../../Assets/Images/dashboard/task-list.png")} />
                            :
                            <KycBanner heading={KYC_STEP.kyc_step === 0 ? "Start your KYC documentation today" : "Complete your KYC documentation today"}
                                description={KYC_STEP.kyc_step === 0 ? "Begin your KYC creation with a PAN number check." : "You are 1 step away from completing your KYC details. Verify your identity for a safer experience."}
                                label={KYC_STEP.kyc_step === 0 ? "Create an account" : "Resume now"}
                                backgroundColor={Color.pastel_blue}
                                image={require("../../Assets/Images/dashboard/task-list.png")} />

            }
            {
                initiateExternalFunds === true ?
                    <ImportExternalFundModal close={_handleInitiateClose}
                        fundImported={_navigate} />
                    :
                    null
            }
        </Fragment>
    )
}