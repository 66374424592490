/*
 *   File : manage-phone.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to update phone number of User.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { GradientButton, SecondaryButton } from "../CTA";
import Icon from "../Icon/icon";
import { OTPInput, CustomPhoneInput, CustomSelectBox } from "../Form-elements";

import { useKeyHandler } from "../../Helper";
import APIService from "../../Service/api-service";
import { _getMobileOrEmailRelation } from "../../Helper/api";


import style from "../../Styles/Component/manage.module.scss";
import Color from "../../Styles/color.module.scss";

const ManagePhoneModal = (props) => {
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [phone, setPhone] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [oldOtp, setOldOtp] = useState("");
    const [newOtp, setNewOtp] = useState("");
    const [newOtpError, setNewOtpError] = useState("");
    const [loader, setLoader] = useState(false);
    const [phoneError, setPhoneError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [phoneExisted, setPhoneExisted] = useState(false);
    const [pinString, setPinString] = useState('');
    const [pinError, setPinError] = useState('');
    const [selectedRelation, setSelectedRelation] = useState("");
    const [relationError, setRelationError] = useState("");
    const [relationOptions, setRelationOptions] = useState([]);

    useEffect(() => {
        var my_modal = document.getElementById("manage-phone");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.onCloseModal();
        });
    }, []);

    useEffect(() => {
        _getRelation();
    }, []);

    useEffect(() => {
        if (props.phone !== "-" && props.phone) {
            setPhoneExisted(true);
        }
    }, [props.phone]);


    useEffect(() => {
        if (otpSent === true) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        }
        else {
            setSeconds(30);
        }

    }, [seconds, otpSent]);

    // useKeyHandler('manage-phone', 'a', (event) => {
    //     if (event.ctrlKey || event.metaKey) {
    //         event.preventDefault();
    //     }
    // });

    const _handleGoBack = (type) => {
        setOtpError("")
        setOldOtp("");
        setNewOtp("");
        setNewOtpError("");
        setOtpSent(false);
    }

    const _handleChange = (type, value) => {
        switch (type) {
            case 1:
                setOldOtp(value);
                setOtpError("");
                break;
            case 2:
                setNewOtp(value);
                setNewOtpError("");
                break;
            case 3:
                setPinString(value);
                setPinError("");
                break;
        }
    };

    function _validateOtp() {
        let valid = true
        if (oldOtp.length < 4) {
            valid = false;
            setOtpError('OTP require');
        }
        if (newOtp.length < 4) {
            valid = false;
            setNewOtpError('OTP required');
        }
        if (valid === true) {
            // _pinHandler();
            _verifyChangePhone();
            setLoader(true);
        }
    }

    function _validatePIN() {
        if (pinString.length < 4) {
            setPinError('OTP required');
        } else {
            _verifyMobile();
        }
    }

    const _handleInput = (value) => {
        setPhoneError("");
        setPhone(value);
    }

    const _handleSendOTP = () => {
        let valid = true
        if (phone.length < 10) {
            valid = false;
            setPhoneError('Phone number required');
        }
        if (valid === true) {
            if (phoneExisted) {
                _changePhone();
            } else {
                _addPhone();
            }

        }
    }

    const _handleResendOtp = () => {
        setSeconds(30);
        setPinString("");
        _changePhone()
    }

    const _handleSelectBox = (value) => {
        setSelectedRelation(value);
        setRelationError("");
    }

    const _getRelation = async () => {
        let data = await _getMobileOrEmailRelation();
        setRelationOptions(data);
        let relation_index = data.findIndex((item) => item.value === USER_DATA.mobile_relation_id);
        if (relation_index !== -1){
            setSelectedRelation(data[relation_index]);
        }else{
            setSelectedRelation(data[0]);
        }
        
    }

    // API :: add phone nnumber 
    const _addPhone = () => {
        let url = 'profile/add-mobile';
        let data = {
            "mobile": phone
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setOtpSent(true);
                toast.dismiss();
                toast.success('OTP has been sent successfully', {
                    type: "success"
                });
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }


    // API :: verify phone number 
    const _verifyMobile = () => {
        let url = 'profile/verify-mobile';
        let data = {
            "mobile": phone,
            "otp": pinString,
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                props.phoneAdded();
                toast.dismiss();
                toast.success('Mobile number updated successfully', {
                    type: "success"
                });
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // API :: change email 
    const _changePhone = () => {
        let url = 'profile/change-mobile';
        let data = {
            "mobile": phone,
            "relation": selectedRelation.value
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setOtpSent(true);
                toast.dismiss();
                toast.success('OTP has been sent successfully', {
                    type: "success"
                });
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // API :: manage email 
    const _verifyChangePhone = () => {
        let url = 'profile/verify-mobile-otp';
        let data = {
            "current_mobile_otp": oldOtp,
            "new_mobile_otp": newOtp,
            "relation": selectedRelation.value
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('Mobile number updated successfully', {
                    type: "success"
                });
                props.phoneAdded();
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // close modal without any update in data 
    const _closeModal = () => {
        props.onCloseModal();
        document.getElementById("close-modal").dispatchEvent(new Event("click"));
    }


    return (
        <div className={`modal fade ${style.e_manage_modal}`}
            id="manage-phone"
            tabIndex="-1"
            aria-labelledby="manage-phone"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        otpSent ?
                            phoneExisted ?
                                // OTP screen If phone number already existed
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_closeModal} />


                                    <h3 className="color-black e-poppins-medium e-font-16 line-height-16px d-flex align-items-center mb-3 mt-2">
                                        <Icon icon="back-arrow"
                                            size={24}
                                            color={Color.black}
                                            className={`me-2 cursor-pointer`}
                                            onClick={() => _handleGoBack(1)} />Verify OTP
                                    </h3>
                                    <p className="color-outer-space e-poppins-regular e-font-14 mb-4 margin-20px-top pe-5">
                                        Submit OTPs received in existing and new phone numbers
                                    </p>

                                    <p className={`color-black e-poppins-medium e-font-14 mb-1 text-start`}>
                                        Enter OTP received in existing mobile number, <span className="color-outer-space e-poppins-regular">+91 {props.phone}</span>
                                    </p>
                                    <OTPInput id="change-phone-Pin"
                                        autoFocus={true}
                                        className="m-0 w-100"
                                        pinError={otpError}
                                        Otp={oldOtp}
                                        handleChange={(value) => _handleChange(1, value)} />

                                    <p className={`color-black e-poppins-medium e-font-14 mb-1 text-start mt-4`}>
                                        Enter OTP received in new mobile number, <span className="color-outer-space e-poppins-regular">+91 {phone} </span>
                                        <span className="color-primary-color e-poppins-regular e-font-14 line-height-16px mx-1 cursor-pointer" onClick={_handleGoBack}>
                                            Edit
                                        </span>
                                        <Icon icon="Pen-square"
                                            size={24}
                                            className='cursor-pointer'
                                            onClick={_handleGoBack} />

                                    </p>
                                    <OTPInput id="Confirm-Pin"
                                        autoFocus={false}
                                        className="mx-0  margin-32px-bottom w-100"
                                        Otp={newOtp}
                                        pinError={newOtpError}
                                        handleChange={(value) => _handleChange(2, value)} />

                                    <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                        <SecondaryButton label="Cancel"
                                            cancel="modal"
                                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb" />
                                        <GradientButton label="Verify & update"
                                            className="e-font-16 padding-72px-lr padding-12px-tb"
                                            handleClick={_validateOtp} />

                                    </div>
                                    {
                                        seconds === 0 ?
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 mt-3 text-center'
                                                onClick={_handleResendOtp}>Resend OTP</p>
                                            :
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 mt-3 text-center'>Resend OTP in <span className="color-black">{seconds}s</span></p>
                                    }
                                </Fragment>
                                :
                                // OTP screen to set new PIN
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_closeModal} />


                                    <h3 className="color-black e-poppins-medium e-font-16 line-height-16px d-flex align-items-center mb-3 mt-2">
                                        <Icon icon="back-arrow"
                                            size={24}
                                            color={Color.black}
                                            className={`me-2 cursor-pointer`}
                                            onClick={() => _handleGoBack(1)} />Verify OTP
                                    </h3>

                                    <p className="color-outer-space e-poppins-regular e-font-14 mb-4 margin-20px-top pe-5">
                                        Enter OTP received at <span className="color-outer-space e-poppins-regular">{phone}</span>
                                    </p>

                                    <OTPInput title="Enter OTP received to your mobile number*"
                                        id="Otp"
                                        autoFocus={true}
                                        pinString={pinString}
                                        className="m-0 w-100 mb-3"
                                        pinError={pinError}
                                        handleChange={(value) => _handleChange(3, value)} />



                                    <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                        <SecondaryButton label="Cancel"
                                            cancel="modal"
                                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb" />
                                        <GradientButton label="Verify & update"
                                            loading={loader}
                                            className="e-font-16 padding-72px-lr padding-12px-tb"
                                            handleClick={_validatePIN} />

                                    </div>
                                    {
                                        seconds === 0 ?
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 mt-3 text-center'
                                                onClick={_handleResendOtp}>Resend OTP</p>
                                            :
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 mt-3 text-center'>Resend OTP in <span className="color-black">{seconds}s</span></p>
                                    }
                                </Fragment>
                            :
                            <Fragment>
                                <Icon icon="close"
                                    size={24}
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                    onClick={_closeModal} />

                                <h3 className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 mt-2">
                                    {phoneExisted == true ? "Edit number" : "Add number"}
                                </h3>

                                <p className="color-outer-space e-poppins-regular e-font-14 mb-4 mt-4 pe-5">
                                    {phoneExisted ? 'Update current phone number' : 'Add phone number to your profile'}
                                </p>

                                <div className="row">
                                    <div className="col-md-8 col-12 pb-4">
                                        <CustomPhoneInput
                                            label="Enter new mobile number"
                                            postfix="*"
                                            isMobileInput={true}
                                            error={phoneError}
                                            value={phone}
                                            type='number'
                                            handleChange={_handleInput} />
                                    </div>

                                    <div className="col-md-8 ">
                                        <CustomSelectBox value={selectedRelation}
                                            label="Relation"
                                            error={relationError}
                                            postfix="*"
                                            className="margin-32px-bottom "
                                            options={relationOptions}
                                            onSelectChange={_handleSelectBox} />
                                    </div>

                                    <div className="col-12 ">
                                        <div className="d-flex align-items-center mb-2 border-radius-8px bg-antique-white p-2 padding-12px-lr">
                                            <Icon icon="info"
                                                alt="Danger"
                                                width={24}
                                                height={24} />
                                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0 ms-1">
                                                {
                                                    phoneExisted == true ?
                                                        "*We will send an OTP to exiting phone number and new phone number to verify your profile"
                                                        :
                                                        "*Confirm phone number by verifying the OTP"
                                                }
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className="padding-32px-top d-flex justify-content-center gap-8px">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal"
                                        className="e-font-16 e-poppins-regular px-4 padding-12px-tb" />
                                    <GradientButton label="Send OTP"
                                        className="e-font-16 padding-72px-lr padding-12px-tb"
                                        handleClick={_handleSendOTP} />

                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default ManagePhoneModal